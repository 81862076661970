<template>
  <div id="pay-list">
    <van-nav-bar title="充值记录" left-arrow @click-left="goLastPage" />
    <record-list v-for="(item,index) in recordListArr" :key="index" :recordListObj="item" listType="security" />
  </div>
</template>
<script>
import RecordList from "@/components/order/RecordList.vue";
export default {
  data() {
    return {
      recordListArr: [],
    };
  },
  components: {
    RecordList,
  },
  created() {
    this.$http.get("/api/v2/worker/security_deposit/records").then((res) => {
      this.recordListArr = res.data.records;
    });
  },
};
</script>
<style>
</style>